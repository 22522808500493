import axios from 'axios'
import { Api } from './Api'
import { axiosInstance } from "./axios"

export interface IConfirmPhone {
    code?: string
    token?: string
}

export interface ICall {
    phone: string
    waitTime: number
}

export class LoginService extends Api {
    protected PATH = 'v2/auth'
    protected PATH_OLD = 'v1/auth'

    loginBySms = async (phone: string, code?: string) => {
        const groups = await axios.post<IConfirmPhone>(`${this.URL}/${this.PATH_OLD}/login`, {
            phone,
            code,
            type: 'SMS'
        })

        return groups.data
    }
    loginByCall = async (phone: string) => {
        const groups = await axios.post<ICall>(`${this.URL}/${this.PATH}/login`, {
            type: 'CALL',
            phone
        })

        return groups.data
    }

}