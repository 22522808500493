import Page from '../Page/Page'
import classes from './Signup.module.css'
import React, { useEffect, useRef, useState } from 'react'
import { PatternFormat } from 'react-number-format'
import { useCookies } from 'react-cookie'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { DateTime } from 'ts-luxon'
import { SignUpService } from '../../api/SignUpService'
import { ISignup } from '../../interfaces/Signup'
import { Helmet } from 'react-helmet-async'

const getErrorMessage = (msg: string) => {
    switch (msg) {
        case 'code mismatch':
            return 'Неверный код'
        case 'phone must be a valid phone number':
            return 'Неверный формат телефона'
        case 'phone or code are mismatch':
            return 'Неверный номер телефона или смс-код'
        case 'ref customer not found':
            return 'Номер телефон пригласившего не зарегестрирован'
        default:
            return 'Произошла ошибка, попробуйте позже'
    }
}

const Signup = () => {
    const navigate = useNavigate()
    const signupService = new SignUpService()

    const { state } = useLocation()

    const reqCodeBtn = useRef<HTMLButtonElement>(null)
    const verifyBtn = useRef<HTMLButtonElement>(null)
    const codeInputRef = useRef<HTMLDivElement>(null)
    const signupFormRef = useRef<HTMLDivElement>(null)
    const notificationRef = useRef<HTMLParagraphElement>(null)
    const submitRef = useRef<HTMLButtonElement>(null)

    const [cookies, setCookie] = useCookies(['token'])
    const [phone, setPhone] = useState('')
    const [code, setCode] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [dob, setDob] = useState('')
    const [sex, setSex] = useState('female')
    const [disclaimer, setDisclaimer] = useState(true)
    const [refCustomerPhone, setRefCustomerPhone] = useState('')
    const [token, setToken] = useState('')
    const [refInputIsHidden, setRefInputIsHidden] = useState(true)

    const [searchParams] = useSearchParams()

    useEffect(() => {
        if (cookies.token) {
            navigate('/account/profile')
        }

        let [prePhone, preCode] = ['', '']
        const query = new URLSearchParams(window.location.search.substring(1))
        const paramPhone = searchParams.get('phone')
        const paramCode = searchParams.get('code')
        const paramType = searchParams.get('type') || 'SMS'

        if (paramPhone?.length === 11) {
            const pp = paramPhone
            prePhone = `+7 (${pp[1]}${pp[2]}${pp[3]}) ${pp[4]}${pp[5]}${pp[6]}-${pp[7]}${pp[8]}-${pp[9]}${pp[10]}`
        }

        if (paramCode?.length === 5) {
            preCode = paramCode
        }

        if (paramType === 'SMS') {
            if (prePhone !== '' && preCode !== '') {
                setPhone(prePhone)
                setCode(preCode)

                if (codeInputRef && codeInputRef.current) {
                    codeInputRef.current.classList.remove('d-none')
                }

                verifyPhone(prePhone, preCode).then()
            }
        }
        if (paramType == 'CALL') {
            if (prePhone !== '' && state.token) {
                setToken(state.token)
                setPhone(prePhone)
                if (reqCodeBtn.current) reqCodeBtn.current.classList.add('d-none')
            }
        }
    }, [])

    useEffect(() => {
        if (token !== '') {
            if (signupFormRef && signupFormRef.current) {
                signupFormRef.current.classList.remove('d-none')
            }

            const codeInput = document.body.querySelector(
                'input[name="one-time-code"]'
            ) as HTMLInputElement
            if (codeInput) {
                codeInput.classList.add('success')
                codeInput.disabled = true
            }

            const phoneInput = document.body.querySelector(
                'input[name="phone"]'
            ) as HTMLInputElement
            if (phoneInput) {
                phoneInput.disabled = true
            }

            if (verifyBtn && verifyBtn.current) verifyBtn.current.classList.add('d-none')
        }
    }, [token])

    const parsePhone = (phone: string) => phone.replace(/^\+|[\s_()-]/g, '')
    const parseCode = (code: string) => code.replace(/_/g, '')

    const requestCode = async (p: string | null = null) => {
        try {
            if (reqCodeBtn.current) {
                reqCodeBtn.current.classList.remove('d-none')
                reqCodeBtn.current.disabled = true
            }
            // TODO: disable button, handle the error
            await signupService.confirmPhone(p ? parsePhone(p) : parsePhone(phone))
            // TODO: if no error hide reqButton and show codeInput
            if (reqCodeBtn.current) reqCodeBtn.current.classList.add('d-none')

            if (codeInputRef && codeInputRef.current) {
                codeInputRef.current.classList.remove('d-none')
            }

            const codeInput = document.body.querySelector(
                'input[name="one-time-code"]'
            ) as HTMLInputElement
            if (codeInput) {
                codeInput.focus()
            }

            if (verifyBtn.current) verifyBtn.current.classList.remove('d-none')
        } catch (e) {
            const error = e as AxiosError<{
                message: string | string[]
            }>
            if (error.response) {
                if (notificationRef && notificationRef.current) {
                    const errClass = notificationRef.current.classList.contains('error')
                    notificationRef.current.innerHTML = getErrorMessage(
                        typeof error.response.data.message === 'string'
                            ? error.response.data.message || ''
                            : error.response.data.message[0]
                    )
                    if (!errClass) notificationRef.current.classList.add('error')
                }
            }
        } finally {
            if (reqCodeBtn.current) reqCodeBtn.current.disabled = false
        }
    }

    const verifyPhone = async (p: string | null = null, c: string | null = null) => {
        // TODO: disable button, handle error
        try {
            if (verifyBtn.current) verifyBtn.current.disabled = true
            const prePhone = p ? parsePhone(p) : parsePhone(phone)
            const preCode = c ? parseCode(c) : parseCode(code)
            const verifyReq = await signupService.confirmPhone(prePhone, preCode)

            if (verifyReq.token) setToken(verifyReq.token)

            if (reqCodeBtn.current) reqCodeBtn.current.classList.add('d-none')
        } catch (e) {
            const error = e as AxiosError<{
                message: string | string[]
            }>
            if (error.response) {
                if (notificationRef && notificationRef.current) {
                    const errClass = notificationRef.current.classList.contains('error')
                    notificationRef.current.innerHTML = getErrorMessage(
                        typeof error.response.data.message === 'string'
                            ? error.response.data.message || ''
                            : error.response.data.message[0]
                    )
                    if (!errClass) notificationRef.current.classList.add('error')
                }
            }
        } finally {
            if (verifyBtn.current) verifyBtn.current.disabled = false
        }
    }

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
    }

    const signUp = (e: React.FormEvent) => {
        e.preventDefault()
        const dateDob = DateTime.fromFormat(dob, 'dd.LL.y')
        if (notificationRef && notificationRef.current) {
            console.log(dateDob.isValid)
            notificationRef.current.innerText = ''
            const errClass = notificationRef.current.classList.contains('error')

            if (dateDob >= DateTime.now()) {
                notificationRef.current.innerText = 'Необходимо указать правильную дату рождения'
                if (!errClass) notificationRef.current.classList.add('error')
                return
            }

            if (firstName.length < 2) {
                notificationRef.current.innerText = 'Необходимо указать имя'
                if (!errClass) notificationRef.current.classList.add('error')
                return
            }

            if (lastName.length < 2) {
                notificationRef.current.innerText = 'Необходимо указать фамилию'
                if (!errClass) notificationRef.current.classList.add('error')
                return
            }

            if (email.length > 0 && !validateEmail(email)) {
                notificationRef.current.innerText = 'E-mail указан неправильно'
                if (!errClass) notificationRef.current.classList.add('error')
                return
            }

            if (refCustomerPhone.length > 0 && parsePhone(refCustomerPhone).length !== 11) {
                console.log(refCustomerPhone.length, parsePhone(refCustomerPhone))
                notificationRef.current.innerText = 'Неправильно указан телефон реферала'
                if (!errClass) notificationRef.current.classList.add('error')
                return
            }

            if (!dateDob.isValid) {
                notificationRef.current.innerText = 'Необходимо указать корректную дату рождения'
                if (!errClass) notificationRef.current.classList.add('error')
                return
            }

            if (dateDob.year < 1920) {
                notificationRef.current.innerText = 'Необходимо указать год рождения > 1900'
                if (!errClass) notificationRef.current.classList.add('error')
                return
            }
        }

        if (submitRef && submitRef.current) submitRef.current.disabled = true

        const form: ISignup = {
            birthDate: dateDob.toFormat('y-LL-dd'),
            email: email !== '' ? email : undefined,
            firstName: firstName,
            lastName: lastName,
            refCustomerPhone: refCustomerPhone !== '' ? parsePhone(refCustomerPhone) : undefined,
            restrictions: { email: true, push: true, sms: true },
            sex,
            token: token,
        }
        signupService
            .registration(form)
            .then((data) => {
                if (data.token) {
                    const dt = DateTime.now().plus({ days: 7 })
                    setCookie('token', data.token, {
                        path: '/',
                        secure: true,
                        expires: dt.toJSDate(),
                    })

                    navigate('/account/profile')
                }
            })
            .catch((err) => {
                console.log(err)
                const error = err as AxiosError<{
                    message: string | string[]
                }>
                if (error.response) {
                    if (notificationRef && notificationRef.current) {
                        const errClass = notificationRef.current.classList.contains('error')
                        notificationRef.current.innerHTML = getErrorMessage(
                            typeof error.response.data.message === 'string'
                                ? error.response.data.message || ''
                                : error.response.data.message[0]
                        )
                        if (!errClass) notificationRef.current.classList.add('error')
                    }
                }
                // if (notificationRef && notificationRef.current) {
                //     notificationRef.current.innerHTML = "Не удалось зарегистрироватьс. Пожалуйста, попробуйте позже."
                //     notificationRef.current.classList.add("error")
                // }

                if (submitRef && submitRef.current) submitRef.current.disabled = false
            })
    }

    const onPaste = (e: ClipboardEvent, setFunc: React.Dispatch<React.SetStateAction<string>>) => {
        e.stopPropagation()
        e.preventDefault()

        const clipboardData = e.clipboardData
        if (clipboardData) {
            const pastedData = clipboardData.getData('Text')
            setFunc(parsePhone(pastedData))
        }
    }

    const showInputRefNumber = (e: React.MouseEvent) => {
        e.preventDefault()
        setRefInputIsHidden(false)
    }

    const hideInputRefNumber = (e: React.MouseEvent) => {
        e.preventDefault()
        setRefInputIsHidden(true)
        setRefCustomerPhone('')
    }

    return (
        <Page>
            <Helmet prioritizeSeoTags>
                <title>Регистрация в программе лояльности</title>
                <link rel='canonical' href='https://parfumeratelier.ru/signup' />
                <meta property='og:title' content={`Регистрация в программе лояльности`} />
                <meta property='og:url' content={`https://parfumeratelier.ru/signup`} />
                <meta property='og:description' content={`Регистрация в программе лояльности`} />
            </Helmet>

            <div className={classes.signup}>
                <div className={classes.header}>
                    <div className={classes.empty} />
                    <h1>Регистрация</h1>
                    <div />
                </div>

                <form className={classes.form} onSubmit={signUp}>
                    {searchParams.get('type') !== 'CALL' && (
                        <p>Мы отправим на номер SMS-сообщение с кодом подтверждения</p>
                    )}
                    <div className='form__input'>
                        <label>Телефон</label>
                        <PatternFormat
                            name='phone'
                            type='tel'
                            onChange={({ target: { value } }) => setPhone(value)}
                            autoComplete='tel'
                            inputMode='numeric'
                            format='+7 (###) ###-##-##'
                            value={phone}
                            allowEmptyFormatting
                            mask='_ '
                        />
                    </div>

                    <div ref={codeInputRef} className='form__input d-none'>
                        <label>Код из СМС</label>
                        <PatternFormat
                            name='one-time-code'
                            type='text'
                            onChange={({ target: { value } }) => setCode(value)}
                            autoComplete='one-time-code'
                            inputMode='numeric'
                            format='#####'
                            value={code}
                            allowEmptyFormatting
                            mask='_ '
                        />
                    </div>
                    <div className={classes.buttonWrapper}>
                        <button
                            ref={reqCodeBtn}
                            onClick={() => requestCode(null)}
                            className='btn btn-uppercase'
                            disabled={parsePhone(phone).length !== 11}>
                            Получить код
                        </button>

                        <button
                            ref={verifyBtn}
                            onClick={() => verifyPhone(null, null)}
                            className='btn btn-uppercase d-none'
                            disabled={parseCode(code).length !== 5}
                            style={{ marginTop: '2rem' }}>
                            Подтвердить телефон
                        </button>
                    </div>

                    <div ref={signupFormRef} className='d-none'>
                        <div className='form__input'>
                            <label>
                                Имя<sup>*</sup>
                            </label>
                            <input
                                type='text'
                                name='firstName'
                                value={firstName}
                                onChange={({ target: { value } }) => setFirstName(value)}
                            />
                        </div>

                        <div className='form__input'>
                            <label>
                                Фамилия<sup>*</sup>
                            </label>
                            <input
                                type='text'
                                name='lastName'
                                value={lastName}
                                onChange={({ target: { value } }) => setLastName(value)}
                            />
                        </div>

                        <div className='form__input'>
                            <label>
                                Дата рождения<sup>*</sup>
                            </label>
                            <PatternFormat
                                name='dob'
                                type='text'
                                onChange={({ target: { value } }) => setDob(value)}
                                inputMode='numeric'
                                format='##.##.####'
                                allowEmptyFormatting
                                placeholder='Дата рождения'
                                mask='_ '
                            />
                        </div>

                        <div className='form__input'>
                            <label>E-mail</label>
                            <input
                                type='email'
                                name='email'
                                value={email}
                                onChange={({ target: { value } }) => setEmail(value)}
                            />
                        </div>

                        <div className='form__input--radios'>
                            <div className='form__radio'>
                                <input
                                    type='radio'
                                    id='form-sex-female'
                                    name='sex'
                                    value='female'
                                    onChange={({ target: { value } }) => setSex(value)}
                                    defaultChecked
                                />
                                <label htmlFor='form-sex-female'>Женщина</label>
                            </div>

                            <div className='form__radio'>
                                <input
                                    type='radio'
                                    id='form-sex-male'
                                    name='sex'
                                    value='male'
                                    onChange={({ target: { value } }) => setSex(value)}
                                />
                                <label htmlFor='form-sex-male'>Мужчина</label>
                            </div>

                            <div className='form__radio'>
                                <input
                                    type='radio'
                                    id='form-sex-unset'
                                    name='sex'
                                    value='unset'
                                    onChange={({ target: { value } }) => setSex(value)}
                                />
                                <label htmlFor='form-sex-unset'>Не указано</label>
                            </div>
                        </div>

                        <div className={classes.disclaimer}>
                            <div className='form__checkbox'>
                                <input
                                    type='checkbox'
                                    className='checkbox'
                                    id='disclaimer'
                                    name='disclaimer'
                                    defaultChecked
                                    onChange={() => setDisclaimer(!disclaimer)}
                                />
                                <label htmlFor='disclaimer'>
                                    Я соглашаюсь с&nbsp;
                                    <Link to='/documents'>Политикой конфиденциальности</Link>
                                </label>
                            </div>
                        </div>

                        <p className='form__notification' ref={notificationRef} />

                        <button
                            type='submit'
                            className='btn btn-uppercase'
                            ref={submitRef}
                            disabled={
                                !disclaimer ||
                                (searchParams.get('type') === 'SMS' && code.length !== 5)
                            }
                            style={{ marginTop: '2rem' }}>
                            Зарегистрироваться
                        </button>
                    </div>
                </form>
            </div>
        </Page>
    )
}

export default Signup
