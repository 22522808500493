import { Await, useLoaderData, useOutletContext } from 'react-router-dom'
import React, { Suspense, useEffect, useState } from 'react'
import { BannerContextType } from '../Catalog/Catalog'
import { ItemsGrid } from '../../components'
import { Loader } from '../../components/loader/Loader'
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import { IItemValue } from '../../interfaces/Item'
import { IBrand } from '../../interfaces/Brand'
import { ICategory } from '../../interfaces/Category'
import { Helmet } from 'react-helmet-async'

type PromiseType = {
    brand: Promise<Array<IBrand>>
    category: Promise<Array<ICategory>>
    items: Promise<IItemValue>
    dataType: string
}

const defaultBanner = 'https://storage.yandexcloud.net/parfum.public/media/Artboard.jpg'

const Items = () => {
    const { brand, category, items } = useLoaderData() as PromiseType

    const { setBanner, filters, filterPrice, pickedBrands, sortType } =
        useOutletContext<BannerContextType>()
    const [cat, setCat] = useState<ICategory>()
    const [brnd, setBrnd] = useState<IBrand>()

    useEffect(() => {
        Promise.resolve(brand).then((b) => {
            console.log(b)
            setBrnd(b?.at(0))
            if (b && b.length > 0 && b[0].imgUrl.length > 0) {
                setBanner(b[0].imgUrl[0])
            } else {
                setBanner(defaultBanner)
            }
        })
        Promise.resolve(category).then((b) => {
            setCat(b[0])
            if (b && b.length > 0 && b[0].imgUrl.length > 0) {
                setBanner(b[0].imgUrl[0])
            } else {
                setBanner(defaultBanner)
            }
        })
    }, [brand, category])

    return (
        <>
            {cat ? (
                <Helmet prioritizeSeoTags>
                    <title>{cat.name}</title>
                    <link
                        rel='canonical'
                        href={`https://parfumeratelier.ru/categories/${cat.slug}`}
                    />
                    <meta
                        name='description'
                        content={`Большой выбор товаров в категории ${cat.name}`}
                    />
                    <meta property='og:title' content={`${cat.name}`} />
                    <meta
                        property='og:url'
                        content={`https://parfumeratelier.ru/categories/${cat.slug}`}
                    />
                    <meta
                        property='og:description'
                        content={`Большой выбор товаров в категории ${cat.name}`}
                    />
                </Helmet>
            ) : null}
            {brnd ? (
                <Helmet prioritizeSeoTags>
                    <title>Каталог парфюма {brnd.name}</title>
                    <link rel='canonical' href={`https://parfumeratelier.ru/brands/${brnd.slug}`} />
                    <meta
                        name='description'
                        content={`Большой выбор товаров в бренда ${brnd.name}`}
                    />
                    <meta property='og:title' content={`Каталог парфюма  ${brnd.name}`} />
                    <meta
                        property='og:url'
                        content={`https://parfumeratelier.ru/brands/${brnd.slug}`}
                    />
                    <meta
                        property='og:description'
                        content={`Большой выбор товаров в бренда ${brnd.name}`}
                    />
                </Helmet>
            ) : null}
            <Suspense fallback={<Loader />}>
                <Await resolve={items} errorElement={<ErrorElement msg={''} />}>
                    {({ values }) => {
                        return (
                            <ItemsGrid
                                items={values}
                                sortType={sortType}
                                filters={filters}
                                filterPrice={filterPrice}
                                pickedBrands={pickedBrands}
                                fromSearch={false}
                            />
                        )
                    }}
                </Await>
            </Suspense>
        </>
    )
}

export default Items
