import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ICart } from "../../interfaces/Cart"
import { CustomerService } from "../../api/CustomerService"
import ym from "react-yandex-metrika";
// import { OrderService } from "../../api/OrderService";


interface CartState {
    cart: ICart[]
    promocode: string
    promocodePrice: number
    withdrawBonuses: number
}

const customerService = new CustomerService()
// const orderService = new OrderService()

const initialState: CartState = {
    cart: [],
    promocode: "",
    promocodePrice: 0,
    withdrawBonuses: 0
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setPromocode: (state, action: PayloadAction<[string, number]>) => {
            state.promocode = action.payload[0]
            state.promocodePrice = action.payload[1]
            ym('reachGoal', 'PROMOCODE', { code: action.payload[0] })
        },
        withdrawBOnuses: (state, action: PayloadAction<number>) => {
            state.withdrawBonuses = action.payload
        },
        setCart: (state, action: PayloadAction<ICart[]>) => {
            state.cart = action.payload
            customerService.sendCart(state.cart).then().catch()
        },
        addDelivery: (state, action: PayloadAction<ICart>) => {
            const itemsIndexInCartForAdd = state.cart.findIndex(i => {
                return i.product._id === action.payload.product._id
            })

            if (itemsIndexInCartForAdd < 0) {
                state.cart = [...state.cart, action.payload]
            } else {
                state.cart = state.cart.map((c, i) => {
                    if (i === itemsIndexInCartForAdd)
                        c.modifiers = action.payload.modifiers
                    return c
                })
            }

            customerService.sendCart(state.cart).then().catch()
        },
        removeDelivery: (state, action: PayloadAction<string>) => {
            state.cart = state.cart.map(c => {
                if (c.product._id === action.payload)
                    c.amount = 0
                return c
            }).filter(c => c.amount !== 0)

            customerService.sendCart(state.cart).then().catch()
        },
        addToCart: (state, action: PayloadAction<ICart>) => {
            const itemsIndexInCartForAdd = state.cart.findIndex(i => {
                if (i.product._id !== action.payload.product._id) return false
                //TODO: Потом нормально сравнивать массивы модификаторов
                if (!i.modifiers && !action.payload.modifiers) return true
                if (i.modifiers && action.payload.modifiers && i.modifiers.slug === action.payload.modifiers.slug) return true
                return false
            })

            if (itemsIndexInCartForAdd < 0) {
                state.cart = [...state.cart, action.payload]
            } else {
                state.cart = state.cart.map((c, i) => {
                    if (i === itemsIndexInCartForAdd) c.amount++
                    return c
                })
            }

            state.withdrawBonuses = 0
            window._tmr.push({
                type: "reachGoal",
                id: 3288302,
                value: action.payload.product.priceDefault,
                goal: "addToCart",
                params: { product_id: action.payload.product.article }
            })

            window.dataLayer.push({
                'ecommerce': {
                    'currencyCode': 'RUB',
                    'add': {
                        'products': [{
                            'id': action.payload.product.article,
                            'name': action.payload.product.name,
                            'price': action.payload.product.priceDefault,
                            'quantity': 1
                        }]
                    }
                }
            })

            customerService.sendCart(state.cart).then().catch()
        },
        increaseCount: (state, action: PayloadAction<ICart>) => {
            // const itemsIndexInCartForAdd = state.cart.findIndex(i => i._id === action.payload)

            // if (itemsIndexInCartForAdd !== -1) {
            //     state.cart = state.cart.map((c, i) => {
            //         if (i === itemsIndexInCartForAdd) c.amount++
            //         return c
            //     })
            // }
            state.cart = state.cart.map(c => {
                if (c._id === action.payload._id) c.amount++
                return c
            })
            state.withdrawBonuses = 0
            window._tmr.push({
                type: "reachGoal",
                id: 3288302,
                value: action.payload.product.priceDefault,
                goal: "addToCart",
                params: { product_id: action.payload.product.article }
            })

            window.dataLayer.push({
                'ecommerce': {
                    'currencyCode': 'RUB',
                    'add': {
                        'products': [{
                            'id': action.payload.product.article,
                            'name': action.payload.product.name,
                            'price': action.payload.product.priceDefault,
                            'quantity': 1
                        }]
                    }
                }
            })

            customerService.sendCart(state.cart).then().catch()
        },
        decreaseCount: (state, action: PayloadAction<ICart>) => {
            // const itemsIndexInCartForAdd = state.cart.findIndex(i => i._id === action.payload)
            // if (itemsIndexInCartForAdd !== -1) {
            //     state.cart = state.cart.map((c, i) => {
            //         if (i === itemsIndexInCartForAdd) c.amount--
            //         return c
            //     }).filter(c => c.amount !== 0)
            // }

            state.cart = state.cart.map(c => {
                if (c._id === action.payload._id) c.amount--
                return c
            }).filter(c => c.amount !== 0)
            state.withdrawBonuses = 0
            window.dataLayer.push({
                'ecommerce': {
                    'currencyCode': 'RUB',
                    'remove': {
                        'products': [{
                            'id': action.payload.product.article,
                            'name': action.payload.product.name,
                            'price': action.payload.product.priceDefault,
                            'quantity': 1
                        }]
                    }
                }
            })

            customerService.sendCart(state.cart).then().catch()
        },
        clear: (state) => {
            state.cart.forEach(c => {
                window.dataLayer.push({
                    'ecommerce': {
                        'currencyCode': 'RUB',
                        'remove': {
                            'products': [{
                                'id': c.product.article,
                                'name': c.product.name,
                                'price': c.product.priceDefault,
                                'quantity': 1
                            }]
                        }
                    }
                })
            })
            state.cart = []
            state.withdrawBonuses = 0
            customerService.sendCart([]).then().catch()
        },
    }
})


export const cartReducer = cartSlice.reducer
export const cartActions = cartSlice.actions